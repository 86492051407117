
const LessonTheatreSteps = [
    { // step 0 - intro
      size : "ROW",
      name: "general.intro", 
      description: "course.theatre.lesson-background.description",
      AnimalName : "bunny",
      videoThumbnail: "/assets/course/theatre/lesson-thumbnail_background.jpeg",
      id: "theatre_intro",
      stepItems : [
        { 
          id: "theatre_intro_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_intro.mp4",
          videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
          isStepGoal: true
        }
      ]
    },
    { // Skill
      buttonShape: "circle", // 
      size : "coinImg",
      name: "teamworkSkills.stop-to-relax.name", 
      description: "teamworkSkills.stop-to-relax.description",
      todo: "teamworkSkills.stop-to-relax.todo",
      coinName: "stop-to-relax",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/how-to-stop-the-anger.jpg",
      id: "tempSkillForTheatre",
      stepItems : [
        { 
          id: "tempSkillForTheatre_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/how-to-stop-the-anger.mp4",
          isStepGoal: true
        }
      ]
    },
    { // glue on cardboard
      StepNumberToShow: 1,
      name: "course.theatre.lesson-house.steps.glue-on-cardboard.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-house.steps.glue-on-cardboard.description",
      requirements: [{name: "activityPage"}, {name: "cardboard"}, {name: "stick-glue"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-01_glue-on-cardboard.jpg",
      id: "theatre_temp1",
      stepItems : [
        { 
          id: "theatre_temp1_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_temp1_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // cut-the-house
      StepNumberToShow: 2,
      name: "course.theatre.lesson-house.steps.cut-the-house.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-house.steps.cut-the-house.description",
      requirements: [{name: "scissors"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-02_cut-the-house.jpg",
      id: "theatre_temp2",
      stepItems : [
        { 
          id: "theatre_temp2_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_temp2_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // fold-cardboard
      StepNumberToShow: 3,
      name: "course.theatre.lesson-house.steps.fold-cardboard.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-house.steps.fold-cardboard.description",
      requirements: [{name: "scissors"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-03_fold-cardboard.jpg",
      id: "theatre_temp3",
      stepItems : [
        { 
          id: "theatre_temp3_video",
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_temp3_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // base
      StepNumberToShow: 4,
      name: "course.theatre.lesson-house.steps.base.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-house.steps.base.description",
      requirements: [{name: "cardboard"}, {name: "scissors"}, {name: "bottle-glue"}, {name: "tape"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-04_base.jpg",
      id: "theatre_temp4",
      stepItems : [
        { 
          id: "theatre_temp4_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_temp4_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // windows-and-doors 
      StepNumberToShow: 5,
      name: "course.theatre.lesson-house.steps.windows-and-doors.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-house.steps.windows-and-doors.description",
      requirements: [{name: "activityPage"}, {name: "scissors"}, {name: "stick-glue"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-05_windows-and-doors.jpg",
      id: "theatre_temp5",
      stepItems : [
        { 
          id: "theatre_temp5_video",
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_temp5_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // trophy
      name: "finishStep.name",
      size : "CUSTOM-IMG", 
      state: "disabled", 
      ImageToShow: "/assets/icons/icon_trophy.svg",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
      id:"theatre_trophy",
      stepItems : [
        {
          id:"theatre_trophy_discuss",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_wheel-of-creation-poster.jpg",
          discussTitle: "teamworkSkills.wheel-of-creation.discussTitle",
        }
      ]
    }    
    ]
    
    export default LessonTheatreSteps;