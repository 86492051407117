import React, {useState} from "react";
import Animal from "components/Animal/Animal";
import "./LessonStepItem.scss"
import { useTranslation } from 'react-i18next';



function LessonStepItem({
     buttonShape,
     name,
     type,
     size,
     index,
     stepState,
     CurrentSelectedStep,
     setCurrentSelectedStep,
     setCurrentSelectedStepItem,
     CelebrationState,
     classes,
     AnimalName,
     coinName,
     StepNumberToShow,
     lessonCompleted,
     celebrationMoment,
     ImageToShow
    }) {

    const clickOnButton = () => {
        setCurrentSelectedStep(index)
        setCurrentSelectedStepItem(0)
        // console.log("step "+ index + " pressed")
        // new Audio('/assets/audio/tap.mp3').play()
    }
    const {t} = useTranslation();

    return(
        <>
            
            {buttonShape == "coin" && <>
                    <div className={[(stepState === "disabled" ? "disabledClick" : undefined) , " LessonVideoItem" , "lesson-video-item__coin-current" , (stepState === "completed" && "lesson-video-item__coin-completed")].join(" ")} >
                        <div className={[" lesson-video-item__coin--img" , "lesson-video-item__coin--img_current" ,(stepState=="completed" && "lesson-video-item__coin--img_completed")].join(" ")}>
                            <img className={"LessonVideoItem-imageToShow " } src={"/assets/coins/coin_" + coinName + "_on.svg"}/>  
                            {/* (state=="disabled" ? "_off.svg": */}
                        </div>
                        <div className={"LessonVideoItem-title LessonVideoItem-title_current"}>{name}</div>
                        {/* <div className="LessonVideoItem-subtitle ">{t("general.teamworkSkill")}</div> */}
                    </div>
                    {/* <div onClick={clickOnButton} className={[
                        "noselect",
                        "lesson-step-item__coin--container",
                        ("lesson-step-item__coin__state-" + stepState),
                        ((CurrentSelectedStep === index) ? " lesson-step-item__coin-current " : " " ),
                        (celebrationMoment && " bounceToLarge")
                        ].join(" ")
                        }>
                        <div className={"lesson-step-item__coin--img lesson-step-item__coin " + ((CurrentSelectedStep === index) ? " backgroundGlow shadowGlow " : " " ) }>
                            {CurrentSelectedStep === index && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>}
                            {stepState === "completed" && <div className="completedGreenV"><img src="/assets/icons/icon_green_v_completed.svg" alt="icon green V" /></div>}
                            <img className={"shadowGlow"} src={"/assets/coins/coin_" + coinName + "_on.svg"} alt="theCatbears-sel" />
                        </div>
                        <div className="lesson-step-item--text_container">
                            <div className="lesson-step-item--Skillname ">{name}</div>
                            <div className="lesson-step-item--smallText ">{t("general.teamworkSkill")}</div>
                        </div>

                    </div> */}
                </>
            }
            {buttonShape == "circle" &&
                <>
                <div onClick={clickOnButton} className={
                            "noselect lesson-step-item__coin--container lesson-step-item lesson-step-item__coin__state-" + 
                            stepState + 
                            (coinName ? " lesson-step-item__coin " : "") +
                            ((CurrentSelectedStep === index) ? " lesson-step-item-current backgroundGlow shadowGlow " : " " )+ 
                           
                            " lesson-step-item__state-" + CelebrationState + " " + 
                            classes
                        }>
                        <div className={"lesson-step-item__coin--img lesson-step-item__coin " }>
                        {/* {CurrentSelectedStep === index && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>} */}
                            {/* {stepState === "completed" && <div className="completedGreenV"><img src="/assets/icons/icon_green_v_completed.svg" alt="icon green V" /></div>} */}
                            <img src={"/assets/coins/coin_" + coinName + "_on.svg"} alt="theCatbears-sel" />
                        </div>
                        {CurrentSelectedStep === index && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>}
                        {stepState === "completed" && <div className="completedGreenV"><img src="/assets/icons/icon_green_v_completed.svg" alt="icon green V" /></div>}
                        {/* {console.log("CelebrationState ", CelebrationState)} */}
                        {ImageToShow && 
                                <>
                                    <img className="lesson-step-imageToShow" src={ImageToShow}/>
                                </>
                        }   
                         <div className="lesson-step-item--text_container">
                            <div className="lesson-step-item--Skillname ">{name}</div>
                            <div className="lesson-step-item--smallText ">{t("general.teamworkSkill")}</div>
                        </div>
                </div>

                </>
            }
            {buttonShape == "rectangle" &&
                <>
                <div onClick={clickOnButton} className={
                        "noselect lesson-step-item lesson-step-item__state-" + 
                        stepState + 
                        (coinName ? " lesson-step-item__coin " : "") +
                        ((CurrentSelectedStep === index) ? " lesson-step-item-current backgroundGlow shadowGlow " : " " )+ 
                        " lesson-step-item__size-" + (size) + 
                        " lesson-step-item__state-" + CelebrationState + " " + 
                        classes
                    }>
                    {CurrentSelectedStep === index && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>}
                    {stepState === "completed" && <div className="completedGreenV"><img src="/assets/icons/icon_green_v_completed.svg" alt="icon green V" /></div>}
                    {/* {console.log("CelebrationState ", CelebrationState)} */}
                    {ImageToShow && 
                            <>
                                <img className="lesson-step-imageToShow" src={ImageToShow}/>
                            </>
                    }        
                    {AnimalName &&
                        <>
                            {AnimalName && (
                                <div className="lesson-step-item--container lesson-step-img">
                                    <Animal id={AnimalName} alt="my animal" />
                                </div>
                            )}
    
                        </>
                    }
                    {StepNumberToShow &&
                        <div className="lesson-step-item--container">
                            <div className="lesson-step-item--number">{StepNumberToShow}</div>
                        </div>
                    }
                    

                
                <div className="lesson-step-item--name ">{name}</div>
                {type == "conflict-simulator" && <div className="lesson-step-item--smallText ">{t("general.practice-what-we-leanered")}</div>}
            </div>
                </>
            }
            
        </> 
    )

}

export default LessonStepItem