import React, { useContext, useState, useRef , useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenStepper from 'components/FullScreenStepper/FullScreenStepper';
import Q_RatingNumeric from 'components/Q_Elements/Q_RatingNumeric/Q_RatingNumeric';
import "./lessonFeedback.scss";
import UserContext from "../../pages/Login/UserContext";
import Onboarding_Register from 'components/Onboarding/Onboarding_Register';
import addFeedbackToDB from 'utils/addFeedbackToDB';

const LessonFeedback = ({ onFinish }) => {
  const { t } = useTranslation();
  const { user, handleUserUpdate } = useContext(UserContext);
  const [ratingValue, setRatingValue] = useState(null);
  const textAreaRef = useRef(null);
  
  const [Msg, setMsg] = useState("");
  
  const handleRatingSelect = (rating) => {
    setRatingValue(rating);
    console.log("Selected Rating:", rating); // Logs the selected number
  };

  useEffect(() => {
    console.log("lllllll")
  }, [Msg]);


  const handleTextChange = (e) => {
    setMsg(e.target.value)
    console.log("text change ", Msg)
  }
  const { submitFeedback, loading } = addFeedbackToDB(handleUserUpdate, user);
  
  const saveAndContinue = async (onContinue) => {
    if (ratingValue) {
      await submitFeedback(ratingValue);
      onContinue();
    }
  };
  // const saveAndContinue = async (onContinue) => {
  //   if (ratingValue && textAreaRef.current.value.trim()) {
  //     await submitFeedback(ratingValue, textAreaRef.current.value);
  //     onContinue();
  //   }
  // };

  const LessonLikelihood = ({ onContinue }) => (
    <>
        <Q_RatingNumeric
          maxNum={5}
          title={t("general.howWasLesson")}
          textBelowMin={t("general.notGood")}
          textBelowMax={t("general.good")}
          onSelect={handleRatingSelect}
          selectedNum={ratingValue}
        />

    <form id="thisForm" onSubmit={(e) => { e.preventDefault(); saveAndContinue(onContinue);}}>
      <div>
        {/* <p className="lessonFeedback_subtitle">{t("general.tellUs")}</p> */}
        {/* <textarea
          ref={textAreaRef}
          className="form-textarea"
          rows="4"
          id="lesson-feedback-text"
          name="lesson-feedback-text"
          placeholder={t("general.feedback-placeholder")}
          onChange={handleTextChange}
          value={Msg && Msg}
        /> */}
      </div>
      <div className="lessonFeedback-footer noselect">
      </div>
    </form>
    <button
      form="thisForm"
      className={`button Onboarding-button catbearsCTA-primary ${!ratingValue && 'buttonDisabled'}`}
      type="submit"
      disabled={!ratingValue || loading}
    >
      {t("general.continue")}
    </button>
    </>
  );

  const getSteps = () => {
    const steps = [<LessonLikelihood />];
    if (user?.isGuest) {
      steps.push(<Onboarding_Register />);
    }
    return steps;
  };

  return (
    <FullScreenStepper
      steps={getSteps()}
      onFinish={onFinish}
      onExit={() => console.log('Exit!')}
    />
  );
};

export default LessonFeedback;
