import React , {useState, useEffect} from "react";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import "./Discuss.scss"
import Titleblock from "components/PageBlocks/TitleBlock";
import FooterStickyBlock from "components/PageBlocks/FooterStickyBlock";
import PageWrapperBlock from "components/PageBlocks/PageWrapperBlock";
import ContentWrapperBlock from "components/PageBlocks/ContentWrapperBlock";
import getHourAndDate from 'utils/getHourAndDate';



const Discuss = ({
    img, 
    // playRefAudio,
    // pauseRefAudio,
    // teamworkMusicRef,
    stepItemId,
    addEvent,
    onContinue,
    onBack, 
    CurrentSelectedStepItem,
    discussTitle,
    discussSubtitle,
    question
    // AnimalType,
    // instructorName,
    }) => {
    
    // useEffect(() => {
    //     playRefAudio(teamworkMusicRef, true);
    // }, []);
    
        
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    // playRefAudio(teamworkMusicRef, true)

    function handleContinueDiscuss () {
        addEvent("continue", "stepItem", "Discuss", CurrentSelectedStepItem, stepItemId, getHourAndDate())
        onContinue()
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "Discuss", CurrentSelectedStepItem, stepItemId, getHourAndDate());
        onBack()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
          switch (event.key) {
            case ' ':
            case 'Enter':
              handleContinueDiscuss();
              break;
            case 'Delete':
            case 'Backspace':
              handleOnBack();
              break;
            default:
              break;
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
      return (
          <>
            <div className={"Onboarding-content discuss-top-padding hideScrollbar"}>
              <div className={"Onboarding-title"}>{t(discussTitle)}</div>
              <div className={"Onboarding-subtitle"}>{t(discussSubtitle)}</div>
                <div className={"ContentWrapperBlock-flex-column"}>
                    <div className="ContentWrapperBlock-video-container">  
                        <img src={img}/>
                    </div> 
                </div>
          </div>
          <div className={"Onboarding-footer"}>
                {(CurrentSelectedStepItem > 0) && <button onClick={handleOnBack} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary "}>
                    {t("general.replay")}
                </button> }         
                <button onClick={handleContinueDiscuss} className={"button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    <img src="/assets/icons/icon_arrow-forward.svg" alt="forward arrow" /> {t("general.continue")} 
                </button>
            </div>
        </>
    )
}

export default Discuss
