import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { updateUserFeedback } from '../graphql/mutations';
import getHourAndDate from './getHourAndDate';

const useAddFeedbackToDB = (handleUserUpdate, user) => {
  const [updateUser, { loading, error }] = useMutation(gql(updateUserFeedback), {
    update(cache, { data: { setUserFeedback } }) {
      if (setUserFeedback) {
        handleUserUpdate(setUserFeedback);
      } else {
        console.error('setUserFeedback is undefined or null');
      }
    }
  });

  const createFeedbackEntry = (rating, text) => ({
    name: '1stFeedback',
    rating,
    text,
    date: getHourAndDate(),
  });

  const submitFeedback = async (rating, text) => {
    try {
      const currentFeedbacks = user.userFeedback || {};
      const newFeedbackKey = `feedback_${Object.keys(currentFeedbacks).length + 1}`;

      const updatedFeedbacks = {
        ...currentFeedbacks,
        [newFeedbackKey]: createFeedbackEntry(rating, text),
      };

      await updateUser({ variables: { userFeedback: updatedFeedbacks } });
      console.log('User feedback updated successfully:', updatedFeedbacks);
    } catch (error) {
      console.error('Error updating user feedback:', error);
    }
  };

  return { submitFeedback, loading, error };
};

export default useAddFeedbackToDB;
