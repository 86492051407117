import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import CatbearsFeedbackBtn from 'components/CatbearsFeedbackBtn/CatbearsFeedbackBtn';
import "./LogoCatbears.scss"
import FeedbackPopup from 'components/Popups/FeedbackPopup/FeedbackPopup';
import { GlobalContext } from "context/GlobalContext";

function LogoCatbears({isSoundSwitch, hideLogoOnMobile, isCentered, isTapToLandingPage}) {

  const {ShowFeedback, setShowFeedback} = useContext(GlobalContext);


  function closeFeedbackPopup () {
    setShowFeedback(false)
  }

  function onClick (){
    if(isTapToLandingPage) window.location.href = 'https://catbears.com';
  }
  const {i18n } = useTranslation();

  return (
    <>
      {ShowFeedback && 
        <FeedbackPopup 
          closeFeedbackPopup={closeFeedbackPopup}
          feedbackSource={"theatre"}
        />
      }
    <div onClick={onClick} className={"LogoCatbears-container noselect " + (hideLogoOnMobile && "hideLogoOnMobile") + " " + (isCentered && "LogoCatbears-container__centered")}>
      <div className="toggle-switch hovering">
        {isSoundSwitch && <ToggleSwitch /> }
      </div>
        <img className="catbears-logo hovering" src={(i18n.translator.language == `en` ? "/assets/icons/logo-catbears__en.png" : "/assets/icons/logo-catbears__he.png")}/>
    </div>
        <CatbearsFeedbackBtn />
    </>
  );
}

export default LogoCatbears;
