import React , {useState, useRef, useEffect}from "react";
import SideBarPanelNav from "components/SideBarPanelNav/SideBarPanelNav";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import Card from "components/Card/Card";
import ConflictSimulatorKeyboard from "components/ConflictSimulatorKeyboard/ConflictSimulatorKeyboard";
import "./ConflictSimulatorNeeds.scss"
import getHourAndDate from 'utils/getHourAndDate';

const ConflictSimulatorNeeds = ({
    cards,
    stepName,
    taskTitle,
    taskSubTitle,
    stepItemId,
    addEvent,
    CurrentSelectedStepItem,
    thumbnailUrl,
    backgroundColor
    }) => {
    
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    const cardGalleryRef = useRef();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [keyboardState, setKeyboardState] = useState({isOpen: false});
    const [cardEditorState, setCardEditorState] = useState({isOpen: false});    
    const [SelectedCards, setSelectedCards] = useState([]);
    const [ShouldOpenKeyboard, setShouldOpenKeyboard] = useState(false);
    const [InputFullToMax, setInputFullToMax] = useState(false)
    const [ShouldEnableCTA, setShouldEnableCTA] = useState(false);
    

    function getBackgroundColor(backgroundColor) {
        if (backgroundColor.includes("--color-")) {
            return `var(${backgroundColor})`;
        }
        return backgroundColor;
    }
    // const CardsArr = ["rest", "Appriciation", "Love", "friendship", "Get support", "Creativity" ,"freedom", "To be understood", "Patience", "Kindness" ]
    const CardsArr = cards;
    const maxSelectedCards = 3;
    
    const handleContinue = () => {
        addEvent && addEvent("continue", "stepItem", "ConflictSimulatorNeeds", CurrentSelectedStepItem, stepItemId, getHourAndDate());
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "ConflictSimulatorNeeds", CurrentSelectedStepItem, stepItemId, getHourAndDate());
    }

    document.documentElement.style.setProperty("--conflictSimulatorPage-background-color", backgroundColor ? getBackgroundColor(backgroundColor) : "red");
    
    useEffect(() => {
        if(InputFullToMax){
            setShouldEnableCTA(true)
        }else{
            setShouldEnableCTA(false)
        }
      }, [InputFullToMax]);
    
    function renderSelectedCards(maxSelectedCards, selectedCards) {
        const renderedCards = [];
      
        for (let i = 0; i < maxSelectedCards; i++) {
          const cardContent = selectedCards[i] ? (
            // <Card key={i} CardType = "NEED" title={selectedCards[i].title} />
            <Card
                key={i}  // Add a unique key for each element in the array
                cardType={"NEED"}
                emoji={selectedCards[i].emoji}
                modifier={selectedCards[i].modifier}
                title={selectedCards[i].title}         
                onClick={() => setShouldOpenKeyboard(true)}
                // classes={"hoverScale bounce"}
            />
          ) : (
            <div key={i} onClick = {() => setShouldOpenKeyboard(true)} className="card card-placeholder card--need ">
                {/* + */}
                    <img src="/assets/icons/icon_plus.png" alt="plus icon"/>
                </div> 
          );
      
          renderedCards.push(
            <div key={i} className="selected-card-container">
              {cardContent}
            </div>
          );
        }
      
        return renderedCards;
      }

    return (
        <>
            {ShouldOpenKeyboard && <ConflictSimulatorKeyboard 
                shouldOpenKeyboard = {ShouldOpenKeyboard}
                setShouldOpenKeyboard = {setShouldOpenKeyboard}
                CardsArr = {CardsArr}
                CardType = "NEED"
                SelectedCards = {SelectedCards}
                setSelectedCards = {setSelectedCards}
                maxSelectedCards={maxSelectedCards}
                setInputFullToMax = {setInputFullToMax}
                />}
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content Onboarding-container-no-padding assignBackgroundColor hideScrollbar"}>
                    <div className={"Onboarding-title ConflictSimulatorNeeds_stepName assignBackgroundColor"}>{t(stepName)}</div>
                    <div className="ConflictSimulatorNeeds-container">
                        <div className="ConflictSimulatorNeeds__side-a">
                            <div className="ConflictSimulatorNeed-content" >
                                <div className="ConflictSimulatorNeeds__title">{t(taskTitle)}</div>
                                <p className="ConflictSimulatorNeeds__p">{t(taskSubTitle)}</p>
                            <div className="ConflictSimulatorNeeds__cards">
                                {renderSelectedCards(maxSelectedCards, SelectedCards)}
                            </div>
                            </div>
                        {/* <div className="ConflictSimulatorNeeds-below"> */}
                                {/* <div className="ConflictSimulatorNeeds-buttons row-buttons">
                                    <button className={"catbearsCTA "} onClick={handleOnBack}>
                                    {t("general.back")}
                                    </button>
                                    <button className={"catbearsCTA " + (ShouldEnableCTA ? " " : "buttonDisabled")} onClick={handleContinue}>
                                        <div className="text">{t("general.continue")}</div>
                                        <div className="icon icon-rtl"><img className="video-controlls__img" src="/assets/icons/icon_arrow-forward.svg" alt="catbears_video_control_icon"/></div>    
                                    </button>
                            
                                </div> */}
                            {/* </div> */}
                        </div>
                        <div className="ConflictSimulatorNeeds__side-b">                    
                            <div className="ConflictSimulatorNeeds-img-container">
                                <img className="ConflictSimulatorNeeds-img" src={thumbnailUrl} alt="video thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"Onboarding-footer assignBackgroundColor"}>
                    <button onClick={handleOnBack} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary "}>
                        {t("general.back")}
                    </button>          
                    <button onClick={handleContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button " + (ShouldEnableCTA ? " " : "buttonDisabled")}>
                        <img src="/assets/icons/icon_arrow-forward.svg" alt="forward arrow" /> {t("general.continue")} 
                    </button>
                </div>
            </div>
        </>
    )
}

export default ConflictSimulatorNeeds