
import "./TodoPopup.scss"
import "../Popups.scss"
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import printFunc from 'utils/printFunc';
import StepRequirement from "components/StepRequirement/StepRequirement";
import { sendCustomEmail as sendCustomEmailMutation } from '../../../graphql/mutations';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

const TodoPopup = ({closeTodoPopup, lessonRequirements, pathname, title, activityPageThumb, activityPagePDF, userHasEmail}) => {
    const {t} = useTranslation();
    console.log("title", title)

    const [UserEmail, setUserEmail] = useState(userHasEmail ? userHasEmail : "");
    
    
    const [EmailSent, setEmailSent] = useState(false);

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    // const [sendFeedback] = useMutation(gql(mailFeedbackMutation));
    // const userSendFeedback = () => {
    //     sendFeedback({ variables: { feedback: "yo" } })
    // }
    console.log("lessonRequirements ", lessonRequirements)
    const formatLessonRequirements = (lessonRequirements) => {
        const filteredRequirements = lessonRequirements.filter(req => req.name !== "activityPage");
    
        return filteredRequirements.map((req, index) => {
            const reqName = t(`requirements.${req.name}`);
            const description = t(`requirements.${req.name}-description`);
    
            return `
                <div style="margin-bottom: 10px;">
                    <p style="font-weight: 500; margin-bottom: 5px;">${index + 1}. ${reqName}</p>
                    ${description && description !== `requirements.${req.name}-description` 
                        ? `<p style="font-size: 15px; margin: 0; color: gray">${description}</p>` 
                        : ''}
                </div>
            `;
        }).join('');
    };
    
    
    
    
    const [sendCustomEmail] = useMutation(gql(sendCustomEmailMutation));
    console.log("activityPageThumb",activityPageThumb)

    const handleSendEmail = () => {
        setEmailSent(true)
        setTimeout(() => {
            setEmailSent(false)
          }, 3000); 

        const email = UserEmail;
        const subject = `${t("onboarding.whatNeedForLesson")} "${title}"`;
        const link = "www.catbears.com";
        const listRequirements = formatLessonRequirements(lessonRequirements);
        const content = `
                        <div style="direction: rtl; font-size: 16px; font-family: 'Fredoka', sans-serif; text-align: start; padding: 0 12px">
                             <div id="catbears-header" style="border-radius: 10px; width: 100%; max-width: 630px; background-color: #00abfd; padding: 10px;">
                                    <table role="presentation" style="width: 100%; border-collapse: collapse; vertical-align: middle; margin: 0 auto;">
                                        <tr>
                                            <!-- Logo Cell -->
                                            <td style="width: 125px; vertical-align: middle;">
                                                <a href="https://catbears.com">
                                                    <img src="https://media.catbears.com/catbears-logo-he-square.png" alt="The Catbears Logo" style="display: block; width: 100px; margin: 8px 20px;">
                                                </a>
                                            </td>
                                            <!-- Text Cell -->
                                            <td style="vertical-align: middle;">
                                                <div style="color: white; line-height: 25px; margin-top: 5px; font-size: 30px; font-weight: 600;">הדובתולים</div>
                                                <div style="color: white; font-size: 23px; font-weight: 500;">לומדים ליצור ביחד</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            <div style="background-color: white; width: 100%; height: fit-content; max-width: 630px;">
                                <p>היי חברות וחברים!<br>
                                הנה רשימת הדברים שצריך לשיעור <strong>${title}</strong> 🎉</p>

                                <p style="font-weight:500">דפי הפעילות להדפסה:</p>
                                <a href="${activityPagePDF}" style="color: blue; text-decoration: underline;">
                                    <img src="${activityPageThumb}" alt="Activity Image" style="margin: 10px 0; width: 120px; height: 170px;border: 1px solid #757575;display: flex; flex-direction: column; align-items: flex-start;">
                                    <p>דפי פעילות להדפסה</p>
                                </a>

                                <p style="font-weight:500; border-top: 1px solid #e2e2e2; padding-top: 20px;">רשימת החומרים שתצטרכו:<br>
                                ${listRequirements}</p>

                                <p style="font-weight:500; border-top: 1px solid #e2e2e2; padding-top: 20px;">⏰ הפעילות נמשכת בין חצי שעה לשעה, תלוי במספר המשתתפים</p>
                                <p style="font-weight:500; border-top: 1px solid #e2e2e2; padding-top: 20px;">🐝בזמן שאנחנו יוצרים נלמד לעבוד טוב ביחד כצוות יצירה! </p>

                                <div style="margin-top: 50px; margin-bottom: 50px; text-align: right;">
                                    <a href="https://app.catbears.com" style="
                                    display: inline-block;
                                    padding: 14px 26px;
                                    color: white;
                                    font-weight: 600;
                                    min-width: 190px;
                                    background-color: #00ca00;
                                    text-decoration: none;
                                    border-radius: 60px;
                                    font-size: 24px;
                                    text-align: center;
                                    letter-spacing: 0.8px;
                                    ">
                                    אל הדובתולים
                                    </a>
                                </div>

                                <p>באהבה, מצוות הדובתולים</p>
                                <strong>קדימה להתלכלך!</strong>
                                <p style="line-height:0px; border-top: 1px solid #e2e2e2; padding-top: 20px; ">📸 צלמו ותייגו את היצירות בסושיאל שלכם:</p>
                                <p style="font-weight:600; direction: ltr; text-align: right; border-bottom: 1px solid #e2e2e2 ; width:fit-content; background-color: #f2dd3d">#thecatbears</p>
                            </div>
                        </div>
`;

        const body = `<div>${content}</div>`;
        sendCustomEmail({ variables: { email, subject, body } });
    };
    
    const handleOnPrint = () => {
        printFunc(activityPagePDF)
    }

    

    const sendItToMyEmail = ({ title = "", content = "", footer = "", img = "" }) => {
        const message = `
            <div style="font-family: Arial, sans-serif;">
                <h2>${title}</h2>
                <p>${content}</p>
                ${img ? `<img src="${img}" alt="Image" style="max-width: 100%; height: auto;"/>` : ""}
                <p>${footer}</p>
            </div>
        `;
    
        const subject = encodeURIComponent(title);
        const body = encodeURIComponent(message);
    
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };
    
    return (
        <>
            <div className='Popup'>
                <div className='Popup__container '>
                    <div className='Popup__header'>
                        <div className="Popup__subtitle">{t("onboarding.whatNeedForLesson")} "{title}"</div>
                        <div onClick={closeTodoPopup} className="Popup__x"><img src="/assets/icons/iconX.svg" alt="icon close"/></div>
                    </div>
                    <div className='Popup__content Popup__content--todo'>
                        <div className="todoPopup__activityPage">
                            <div className="todoPopup__activityPage--top">
                                <img className="activityPage-img" src={activityPageThumb} alt="activity page"/>
                                <div className="activityPage-txt">
                                    <div className="todoPopup-title">{t("onboarding.Onboarding_Print.title")}</div>
                                    <div className="todoPopup-subtitle">{t("onboarding.Onboarding_Print.subtitle")}</div>
                                    <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA todoPopupBtn todoPopupBtn-desktop "}>
                                        <img src="/assets/icons/icon-print.svg" />
                                        {t("general.printActivityPages")}
                                    </button> 
                                </div>
                            </div>
                            <div className="todoPopup__activityPage--bottom">
                                <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA todoPopupBtn todoPopupBtn-mobile "}>
                                    <img src="/assets/icons/icon-print.svg" />
                                    {t("general.printActivityPages")}
                                </button> 
                            </div>
                        
                        </div>
                        <div className="todoPopup__requirements">
                            <div className="todoPopup-txt">
                                <div className="todoPopup-title">{t("onboarding.Onboarding_Materials.title")}</div>
                                <div className="todoPopup-subtitle">{t("onboarding.Onboarding_Materials.subtitle")}</div>
                            </div>
                            {lessonRequirements.map((Requirement, index) => {
                                        return (
                                            <div className="req-container " key={index}>
                                                {!(Requirement.name === "activityPage") && <StepRequirement
                                                    key={index}
                                                    type={Requirement.name}
                                                    showDescription
                                                    imgPath={activityPageThumb}
                                                    directionRow
                                                    />}
                                            </div>
                                        )
                                    })}
                        </div>
                    </div>
                    <div className={"Popup__footer Popup__footer--todo"}>
                       {!EmailSent ?
                        <> 
                           <form
                                className="input-form"
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevents page reload
                                    handleSendEmail();  // Calls the send email function
                                }}
                            >
                                <div className="input-container">
                                    <img className="todoPopupInput-icon" src="assets/icons/icon_email-b.svg" alt="icon email" />
                                    <input
                                        type="email"
                                        placeholder={t("general.writeYourEmail")}
                                        value={UserEmail}
                                        onChange={handleEmailChange}
                                        className="todoPopupInput"
                                        style={{ padding: '10px', marginRight: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                </div>
                                <button
                                    type="submit" // Makes the button submit the form
                                    className={"PopupBtnFooter buttonCatbears buttonCatbears_CTA"}
                                >
                                    {t("general.sendToMyEmail")}
                                </button>
                            </form>

                        </> : 
                            <div className="PopupSentMsg">
                                <img src="assets/icons/icon_green_v_completed.svg" alt="icon V" />
                                {t("general.emailSent")}
                            </div>
                        }      
                    </div>
                </div>
            </div>
        </>
    )
}

export default TodoPopup;