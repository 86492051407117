
import "./FeedbackPopup.scss"
import "../Popups.scss"
import React, {useState } from "react";
import {useTranslation} from 'react-i18next';
import { mailFeedback as mailFeedbackMutation } from '../../../graphql/mutations';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

const FeedbackPopup = ({closeFeedbackPopup, feedbackSource}) => {
    const {t} = useTranslation();

    const [UserInput, setUserInput] = useState("");
    const [EmailSent, setEmailSent] = useState(false);

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };
    
    const [sendFeedback] = useMutation(gql(mailFeedbackMutation));
    const userSendFeedback = () => {
        if (UserInput) {
            setEmailSent(true)
            setTimeout(() => {
                sendFeedback({ variables: { feedback: UserInput } });
                closeFeedbackPopup();
              }, 2000); 
        }
    };
    
    return (
        <>
            <div className='Popup'>
                <div className='Popup__container '>
                    <div className='Popup__header'>
                        <div className="Popup__subtitle">{t("general.feedback-title")}</div>
                        <div onClick={closeFeedbackPopup} className="Popup__x">
                            <img src="/assets/icons/iconX.svg" alt="icon close"/>
                        </div>
                    </div>
                    <div className='Popup__content Popup__content--feedback'>
                        <div className="Popup__title">{t("general.feedback-description")}</div>
                        <form
                            id="myForm"
                            className="input-form"
                            onSubmit={(e) => {
                                e.preventDefault(); 
                                userSendFeedback();
                            }}
                        >
                            <div className="input-container">
                                <textarea
                                    placeholder={t("general.feedback-placeholder")}
                                    value={UserInput}
                                    onChange={handleInputChange}
                                    className="PopupInput"
                                    style={{
                                        width: '100%',
                                        height: '100px',
                                        padding: '10px',
                                        marginRight: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        resize: 'none'
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                    <div className={"Popup__footer"}>
                        {!EmailSent ?
                            <button
                                form="myForm"
                                type="submit"
                                className={"PopupBtnFooter buttonCatbears buttonCatbears_CTA"}
                            >
                                {t("general.send")}
                            </button>
                            :
                            <div className="PopupSentMsg">
                                <img src="/assets/icons/icon_green_v_completed.svg" alt="icon V" />
                                {t("general.thankYou")}
                            </div>
                            }
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default FeedbackPopup;