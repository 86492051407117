
const LessonHouseSteps = [
  { // step 0 - intro
    size : "ROW",
    name: "general.intro", 
    description: "course.theatre.lesson-house.description",
    AnimalName : "crow",
    videoThumbnail: "/assets/course/theatre/lesson-thumbnail_house.jpeg",
    id:"house_intro",
    stepItems : [
      { 
        id:"house_intro_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-00_intro.mp4",
        videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
        isStepGoal: true
      }
    ]
  },
  { // Skill
    id:"stop-to-relax",
    buttonShape: "circle", // 
    size : "coinImg",
    name: "teamworkSkills.stop-to-relax.name", 
    description: "teamworkSkills.stop-to-relax.description",
    coinName: "stop-to-relax",
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/how-to-stop-the-anger.jpg",
    stepItems : [
      { 
        id:"stop-to-relax_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/stop-to-relax.mp4",
        isStepGoal: true
      },
      {
        id:"stop-to-relax_discuss",
        pageState: "discuss",
        img: "/assets/course/theatre/videos-and-thumbnails/stop-the-anger-poster.jpg",
        discussTitle: "teamworkSkills.stop-to-relax.discussTitle",
      },
    ]
  },
  { // glue on cardboard
    StepNumberToShow: 1,
    name: "course.theatre.lesson-house.steps.glue-on-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.glue-on-cardboard.description",
    requirements: [{name: "activityPage"}, {name: "cardboard"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-01_glue-on-cardboard.jpg",
    id:"house_glue-on-cardboard",
    stepItems : [
      { 
        id:"house_glue-on-cardboard_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        isStepGoal: true
      },
      {
        id:"house_glue-on-cardboard_teamwork",
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_01.jpg"
          },
          {
            text: "course.generalSteps.cut-cardboard-light",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/general_cut-cardboard-light.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_04.jpg"
          }
        ],
        tips: ["course.generalTips.cut-cardboard-light"]
      }
    ]
  },
  { // cut-the-house
    StepNumberToShow: 2,
    name: "course.theatre.lesson-house.steps.cut-the-house.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.cut-the-house.description",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-02_cut-the-house.jpg",
    id:"house_cut-the-house",
    stepItems : [
      { 
        id:"house_cut-the-house_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        id:"house_cut-the-house_teamwork",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_03.jpg"
          }
        ]
      }
    ]
  },
  { // fold-cardboard
    StepNumberToShow: 3,
    name: "course.theatre.lesson-house.steps.fold-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.fold-cardboard.description",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-03_fold-cardboard.jpg",
    id:"house_fold-cardboard",
    stepItems : [
      { 
        id:"house_fold-cardboard_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        isStepGoal: true
      },
      {
        id:"house_fold-cardboard_teamwork",
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_04.jpg"
          }
        ]
      }
    ]
  },
  { // base
    StepNumberToShow: 4,
    name: "course.theatre.lesson-house.steps.base.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.base.description",
    requirements: [{name: "cardboard"}, {name: "scissors"}, {name: "bottle-glue"}, {name: "tape"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-04_base.jpg",
    id:"house_base",
    stepItems : [
      { 
        id:"house_base_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        isStepGoal: true
      },
      {
        id:"house_base_teamwork",
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_04.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet5",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_05.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet6",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_06.jpg"
          }
        ],
        tips: ["course.theatre.lesson-house.steps.base.tips.tip1"]
      }
    ]
  },
  { // pink-tree 
    name: "conflict-simulator.pink-tree.name",
    size : "CUSTOM-IMG", 
    state: "selected", 
    description: "conflict-simulator.pink-tree.description",
    ImageToShow: "/assets/course/theatre/videos-and-thumbnails/cat-sad.png",
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb-pink-tree.jpg",
    id:"pink-tree",
    stepItems : [
      {
        id:"pink-tree_let-dry",
        pageState: "discuss",
        img: "/assets/course/let-dry.jpg",
        discussTitle: "let-dry.title",
        discussSubtitle: "let-dry.description"
      },
      {
        id:"pink-tree_video-part-one",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_1.mp4",
        isStepGoal: true
      },
      {
        id:"pink-tree_discuss",
        pageState: "discuss",
        img: "/assets/course/theatre/videos-and-thumbnails/pink-tree_discuss.jpg",
        discussTitle: "conflict-simulator.pink-tree.discussTitle",
        discussSubtitle: "conflict-simulator.pink-tree.discussSubtitle",
      },
      {
        id:"pink-tree_video-part-two",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_2.mp4",
        isStepGoal: true
      },
    ]
  },
  { // windows-and-doors 
    StepNumberToShow: 5,
    name: "course.theatre.lesson-house.steps.windows-and-doors.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.windows-and-doors.description",
    requirements: [{name: "activityPage"}, {name: "scissors"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-05_windows-and-doors.jpg",
    id:"house_windows-and-doors",
    stepItems : [
      { 
        id:"house_windows-and-doors_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        isStepGoal: true
      },
      {
        id:"house_windows-and-doors_teamwork",
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_04.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet5",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_05.jpg"
          }
        ],
        tips: ["course.theatre.lesson-house.steps.windows-and-doors.tips.tip1"]
      }
    ]
  },
  { // color 
    StepNumberToShow: 6,
    name: "course.theatre.lesson-house.steps.color.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.color.description",
    requirements: [{name: "allColors"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-06_color.jpg",
    id:"house_color",
    stepItems : [
      { 
        id:"house_color_video",
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        isStepGoal: true
      },
      {
        id:"house_color_teamwork",
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.generalSteps.all-colors",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/general_all-colors.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.color.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_color_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.color.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_color_03.jpg"
          }
        ]
      }
    ]
  },
    { // trophy
      name: "finishStep.name",
      size : "CUSTOM-IMG",  
      state: "disabled", 
      ImageToShow: "/assets/icons/icon_trophy.svg",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
      id:"house_trophy",
      stepItems : [
        {
          id:"house_trophy_discuss",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_wheel-of-creation-poster.jpg",
          discussTitle: "teamworkSkills.wheel-of-creation.discussTitle",
        }
      ]
    }     
  ]
  
  export default LessonHouseSteps;