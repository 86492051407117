import React, { useState } from 'react';
import './Q_RatingNumeric.scss';

const Q_RatingNumeric = ({ maxNum, textBelowMin, textBelowMax, onSelect, selectedNum, title }) => {


  const handleRangeSelection = (num) => {
    onSelect(num);
    // setTimeout(() => {
    // }, 1000); // Delays by a single render cycle
  };

  return (
    <div className="rating-container">
      {title && <div className="rating-title noselect ">{title}</div>}
      <div className="rating-container__buttons noselect">
      {[...Array(maxNum)].map((_, i) => {
        const num = i + 1;
        return (
          <div
            key={i}
            className={[
              "rating-button selection-button-style",
              (selectedNum === num) ? "selected" : "",
            ].join(" ")}
            onClick={() => handleRangeSelection(num)}
          >
            <div>{num}</div>
          </div>
        );
      })}

      </div>
      <div className="rating-container__texts noselect">
        {textBelowMin && (
          <div className="rating-text ">
            <div>{textBelowMin}</div>
          </div>
        )}
        {textBelowMax && (
          <div className="rating-text">
            <div>{textBelowMax}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Q_RatingNumeric;
