import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './LessonVideoItem.scss';

function LessonVideoItem({pathname, state, isCurrent, name, coinName}){
    const {t} = useTranslation();
    // console.log("^^^^^^ state: ",  state)
    return(
        <>
               {/* (badge ? "LessonListItem__container--list-item__disabled badge_" + badge : 
                 (state=="disabled" ? " LessonListItem__container--list-item__disabled" : " ") + 
               (state=="completed" ? " LessonListItem__container--list-item__completed" : "") 
                 ) */}
            <div className={state === "disabled" ? "disabledClick" : undefined}>
                <Link
                    to={{
                    pathname: `theatre/${pathname}`,
                    // state: { courseName: pathname }
                    }}
                    >
                    <div className={[(state === "disabled" ? "disabledClick" : undefined) , " LessonVideoItem" , (state === "current" && "lesson-video-item__coin-current") , (state === "completed" && "lesson-video-item__coin-completed")].join(" ")} >
                        {isCurrent && <div className='ArrowPointer'><img  src="/assets/icons/icon_arrowSelected.svg"/></div>}
                        <div className={[" lesson-video-item__coin--img video-icon-after " ,(isCurrent && "lesson-video-item__coin--img_current") ,(state=="completed" && "lesson-video-item__coin--img_completed")].join(" ")}>
                            <img className={"LessonVideoItem-imageToShow " } src={"/assets/coins/coin_" + coinName + "_on.svg"}/>  
                            {/* (state=="disabled" ? "_off.svg": */}
                        </div>
                        <div className={"LessonVideoItem-title " + (isCurrent && "LessonVideoItem-title_current")}>{name}</div>
                        {/* <div className="LessonVideoItem-subtitle ">{t("general.teamworkSkill")}</div> */}
                    </div>
                </Link>
            </div>
    </>
    )
}


export default LessonVideoItem;




// import React, { useState } from 'react';
// import {useTranslation} from 'react-i18next';
// import './LessonVideoItem.scss';

// function LessonVideoItem({name, onClick, setLessonVideoItemURL, videoUrl, ImageToShow, CurrentSelectedStep, size, state, isCurrent, title, description, imgPath, activityPagePath, AnimalType, instructorName, badge, stepsCount}){
//     const {t} = useTranslation();

//     const handleSetVideoUrl = () => {
//       setLessonVideoItemURL(videoUrl)
//       onClick();
//     }
//     return(
//             <div className={(state === "disabled" ? "disabledClick" : undefined) + " LessonVideoItem " + (CurrentSelectedStep && "lesson-video-item__coin-current ")} onClick={handleSetVideoUrl}>
//                 <div className={" lesson-video-item__coin--img " }>
//                     <img className={"LessonVideoItem-imageToShow " } src={"/assets/coins/coin_" + ImageToShow + (state=="disabled" ? "_off.svg": "_on.svg")}/>
//                 </div>
//                 <div className="LessonVideoItem-title ">{name}</div>
//                 <div className="LessonVideoItem-subtitle ">{t("general.teamworkSkill")}</div>
//             {/* <div className={[
//                   "LessonVideoItem__container--list-item ",
//                   ("LessonVideoItem__container_" + size),
//                   (isCurrent && "LessonVideoItem__container_current "),
//                   (state=="completed" && "LessonVideoItem__container_completed")
//                   ].join(" ")}>
                  
                  
//                   {isCurrent && <div className='ArrowPointer'><img  src="/assets/icons/icon_arrow-down.svg"/></div>}
//                   <div className={["lessonImg-container", (isCurrent && "backgroundGlow shadowGlow"), (state=="disabled" && "lessonImg-container-disabled")].join(" ")} >
//                   <div className="LessonVideoItem__title">{title}</div>  
//                   {state=="completed" && <img className="Lesson_Trophy bounceDelayed" alt={`Lesson complete!`} src={"assets/icons/icon_trophy.svg"} />}
//                   </div>
//                   </div> */}
//             </div>
//     )
// }


// export default LessonVideoItem;

