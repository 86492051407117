const getProgressAsArr = (userProgress) => {
    if (!userProgress || !userProgress.courses) {
      return [0, 0, 0, 0];
    }
  
    let highestCourseIndex = 0;
    let highestLessonIndex = 0;
    let highestStepIndex = 0;
    let highestStepItemIndex = 0;
  
    userProgress.courses.forEach((course, courseIndex) => {
      if (course.completed) {
        highestCourseIndex = courseIndex + 1;
      } else {
        course.lessons.forEach((lesson, lessonIndex) => {
          if (lesson.completed) {
            highestLessonIndex = lessonIndex + 1;
          } else {
            lesson.steps.forEach((step, stepIndex) => {
              if (step.completed) {
                highestStepIndex = stepIndex + 1;
              } else {
                step.stepItems.forEach((stepItem, stepItemIndex) => {
                  if (stepItem.completed) {
                    highestStepItemIndex = stepItemIndex + 1;
                  }
                });
              }
            });
          }
        });
      }
    });
  
    return [highestCourseIndex, highestLessonIndex, highestStepIndex, highestStepItemIndex];
  };

  function generateProgressTree(courseRecipe) {
    return {
      courses: [{
        id: courseRecipe.id ? courseRecipe.id : "tempID",
        name: courseRecipe.name,
        completed: false,
        lessons: courseRecipe.lessons.map(lesson => ({
          id: lesson.id ? lesson.id : "tempID",
          completed: false,
          steps: Array.isArray(lesson.steps)
            ? lesson.steps.map(step => ({
                id: step.id ? step.id : "tempID",
                completed: false,
                stepItems: Array.isArray(step.stepItems)
                  ? step.stepItems.map(stepItem => ({ 
                    id: stepItem.id ? stepItem.id : "tempID",
                    completed: false 
                  }))
                  : [],
              }))
            : [],
        })),
      }],
    };
  }

  const generateProgressTreeFromArray = (courseRecipe, progressArr) => {
    const [Cprog, Lprog, Sprog, SIprog] = progressArr;
  
    const updatedTree = {
      courses: [{
        name: courseRecipe.name,
        completed: Cprog > 0,
        lessons: courseRecipe.lessons.map((lesson, lIndex) => {
          let lessonCompleted = false;
          if (lIndex < Lprog) {
            lessonCompleted = true;
          } else if (lIndex === Lprog) {
            lessonCompleted = false;
          }
          return {
            completed: lessonCompleted,
            steps: Array.isArray(lesson.steps) ? lesson.steps.map((step, sIndex) => {
              let stepCompleted = false;
              if (lIndex < Lprog || (lIndex === Lprog && sIndex < Sprog)) {
                stepCompleted = true;
              } else if (lIndex === Lprog && sIndex === Sprog) {
                stepCompleted = false;
              }
              return {
                completed: stepCompleted,
                stepItems: Array.isArray(step.stepItems) ? step.stepItems.map((_, siIndex) => {
                  let stepItemCompleted = false;
                  if (lIndex < Lprog || (lIndex === Lprog && sIndex < Sprog) || (lIndex === Lprog && sIndex === Sprog && siIndex <= SIprog)) {
                    stepItemCompleted = true;
                  }
                  return {
                    completed: stepItemCompleted,
                  };
                }) : [],
              };
            }) : [],
          };
        }),
      }],
    };
  
    return updatedTree;
  };

  const getAllTreeItems = (userProgress) => {
    const AllTreeItems = [];
  
    userProgress.courses.forEach((course, courseIndex) => {
      AllTreeItems.push({ type: "course", completed: course.completed, itemIndex: courseIndex });
      
      course.lessons.forEach((lesson, lessonIndex) => {
        AllTreeItems.push({ type: "lesson", completed: lesson.completed, itemIndex: lessonIndex });
  
        lesson.steps.forEach((step, stepIndex) => {
          AllTreeItems.push({ type: "step", completed: step.completed, itemIndex: stepIndex });
          
          step.stepItems.forEach((stepItem, stepItemIndex) => {
            AllTreeItems.push({ type: "stepItem", completed: stepItem.completed, itemIndex: stepItemIndex });
          });
        });
      });
    });
  
    return AllTreeItems;
  };

  const checkForProgressInLesson = (lesson) => {
    // console.log("lesson= ", lesson)
    return lesson.completed || lesson.steps.some(step => 
      step.completed || step.stepItems.some(stepItem => stepItem.completed)
    );
  };
  
  // ------------------------------------------------
  

  const returnVersionReadyProgress = (recipe, userProgress) => {
    const outputJson = { courses: [] };
    let hasChanges = false; // Track if any changes are detected

    // console.log("Starting comparison...");
    recipe.courses.forEach((course, courseIndex) => {
        const courseOutput = {
            id: course.id,
            completed: false, // Default value, updated later if matching userProgress
            lessons: []
        };

        const userCourse = userProgress.courses.find((c) => c.id === course.id);
        if (!userCourse) {
            console.log(`There are changes: Missing/Moving course ${course.id}`);
            hasChanges = true;
            courseOutput.lessons = course.lessons.map((lesson) => ({
                id: lesson.id,
                completed: false,
                steps: lesson.steps.map((step) => ({
                    id: step.id,
                    completed: false,
                    stepItems: step.stepItems.map((stepItem) => ({
                        id: stepItem.id,
                        completed: false
                    }))
                }))
            }));
            outputJson.courses.push(courseOutput);
            return;
        }

        course.lessons.forEach((lesson, lessonIndex) => {
            const lessonOutput = {
                id: lesson.id,
                completed: false,
                steps: []
            };

            const userLesson = userCourse.lessons.find((l) => l.id === lesson.id);
            if (!userLesson) {
                console.log(`There are changes: Missing/Moving lesson ${lesson.id}`);
                hasChanges = true;
                lessonOutput.steps = lesson.steps.map((step) => ({
                    id: step.id,
                    completed: false,
                    stepItems: step.stepItems.map((stepItem) => ({
                        id: stepItem.id,
                        completed: false
                    }))
                }));
                courseOutput.lessons.push(lessonOutput);
                return;
            }

            lesson.steps.forEach((step, stepIndex) => {
                const stepOutput = {
                    id: step.id,
                    completed: false,
                    stepItems: []
                };

                const userStep = userLesson.steps.find((s) => s.id === step.id);
                if (!userStep) {
                    console.log(`There are changes: Missing/Moving step ${step.id}`);
                    hasChanges = true;
                    stepOutput.stepItems = step.stepItems.map((stepItem) => ({
                        id: stepItem.id,
                        completed: false
                    }));
                    lessonOutput.steps.push(stepOutput);
                    return;
                }

                step.stepItems.forEach((stepItem, stepItemIndex) => {
                    const userStepItem = userStep.stepItems.find(
                        (si) => si.id === stepItem.id
                    );
                    if (!userStepItem) {
                        console.log(
                            `There are changes: Missing/Moving stepItem ${stepItem.id}`
                        );
                        hasChanges = true;
                        stepOutput.stepItems.push({
                            id: stepItem.id,
                            completed: false
                        });
                    } else {
                        stepOutput.stepItems.push({
                            id: stepItem.id,
                            completed: userStepItem.completed
                        });
                    }
                });

                stepOutput.completed = userStep.completed;
                lessonOutput.steps.push(stepOutput);
            });

            lessonOutput.completed = userLesson.completed;
            courseOutput.lessons.push(lessonOutput);
        });

        courseOutput.completed = userCourse.completed; // Preserve completed property for the course
        outputJson.courses.push(courseOutput);
    });

    if (!hasChanges) {
        // console.log("No changes to version");
    }

    // console.log("Comparison completed.");
    return outputJson;
};

  export { 
    getProgressAsArr, 
    generateProgressTree, 
    generateProgressTreeFromArray,
    getAllTreeItems,
    checkForProgressInLesson,
    returnVersionReadyProgress
  };
