
const LessonBackgroundSteps = [
    { // step 0 - intro
      size : "ROW",
      name: "general.intro", 
      description: "course.theatre.lesson-background.description",
      AnimalName : "croc",
      videoThumbnail: "/assets/course/theatre/lesson-thumbnail_background.jpeg",
      id:"background_intro",
      stepItems : [
        { 
          id:"background_intro_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_intro.mp4",
          videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
          isStepGoal: true
        }
      ]
    },
    { // Skill
      buttonShape: "circle", // 
      size : "coinImg",
      name: "teamworkSkills.idea-behind-mistake.name", 
      description: "teamworkSkills.idea-behind-mistake.description",
      id:"idea-behind-mistake",
      coinName: "idea-behind-mistake",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_idea-behind-mistake.jpg",
      stepItems : [
        { 
          id:"idea-behind-mistake_video-part-one",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/idea-behind-mistake_1.mp4",
          isStepGoal: true
        },
        {
          id:"idea-behind-mistake_discuss",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/alexander-and-mistake.jpg",
          discussTitle: "teamworkSkills.idea-behind-mistake.discussTitle",
        },
        { 
          id:"idea-behind-mistake_video-part-two",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/idea-behind-mistake_2.mp4",
          isStepGoal: true
        }
      ]
    },  
    { // work-station
      StepNumberToShow: 1,
      name: "course.theatre.lesson-background.steps.work-station.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.work-station.description",
      requirements: [{name: "activityPage"}, {name: "paper-bag"}, {name: "stick-glue"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_work-station.jpg",
      id:"background_work-station",
      stepItems : [
        { 
          id:"background_work-station_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_work-station.mp4",
          isStepGoal: true
        },
        {
          id:"background_work-station_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_work-station.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.generalSteps.cut-paper-bag",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/general_cut-paper-bag.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.work-station.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_work-station_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.work-station.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_work-station_03.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.work-station.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_work-station_04.jpg"
            }
          ]
        }
      ]
    },
    { // color-the-background
      StepNumberToShow: 2,
      name: "course.theatre.lesson-background.steps.color-the-background.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.color-the-background.description",
      requirements: [{name: "brush"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_color-the-background.jpg",
      id:"background_color-the-background",
      stepItems : [
        { 
          id:"background_color-the-background_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_color-the-background.mp4",
          isStepGoal: true
        },
        {
          id:"background_color-the-background_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_color-the-background.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-background.steps.color-the-background.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color-the-background_01.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color-the-background.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color-the-background_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color-the-background.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color-the-background_03.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color-the-background.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color-the-background_04.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color-the-background.bullets.bullet5",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color-the-background_05.jpg"
            }
          ]
        }
      ]
    },
    { // ground
      StepNumberToShow: 3,
      name: "course.theatre.lesson-background.steps.ground.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.ground.description",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_ground.jpg",
      id:"background_ground",
      stepItems : [
        { 
          id:"background_ground_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_ground.mp4",
          isStepGoal: true
        },
        {
          id:"background_ground_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_ground.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-background.steps.ground.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_ground_01.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.ground.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_ground_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.ground.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_ground_03.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.ground.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_ground_04.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.ground.bullets.bullet5",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_ground_05.jpg"
            }
          ]
        }
      ]
    },
    { // hills
      StepNumberToShow: 4,
      name: "course.theatre.lesson-background.steps.hills.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.hills.description",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_hills.jpg",
      id:"background_hills",
      stepItems : [
        { 
          id:"background_hills_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_hills.mp4",
          isStepGoal: true
        },
        {
          id:"background_hills_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_hills.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-background.steps.hills.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_hills_01.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.hills.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_hills_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.hills.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_hills_03.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.hills.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_hills_04.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.hills.bullets.bullet5",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_hills_05.jpg"
            }
          ]
        }
      ]
    },
    { // objects
      StepNumberToShow: 5,
      name: "course.theatre.lesson-background.steps.objects.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.objects.description",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_objects.jpg",
      id:"background_objects",
      stepItems : [
        { 
          id:"background_objects_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_objects.mp4",
          isStepGoal: true
        },
        {
          id:"background_objects_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_objects.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-background.steps.objects.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_objects_01.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.objects.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_objects_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.objects.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_objects_03.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.objects.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_objects_04.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.objects.bullets.bullet5",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_objects_05.jpg"
            }
          ]
        }
      ]
    },
    { // color
      StepNumberToShow: 6,
      name: "course.theatre.lesson-background.steps.color.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.color.description",
      requirements: [{name: "allColors"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_color.jpg",
      id:"background_color",
      stepItems : [
        { 
          id:"background_color_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_color.mp4",
          isStepGoal: true
        },
        {
          id:"background_color_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_color.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.generalSteps.all-colors",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/general_all-colors.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color_02.jpg"
            },
            {
              text: "course.theatre.lesson-background.steps.color.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/background_color_03.jpg"
            }
          ]
        }
      ]
    },
    { // trophy
      name: "finishStep.name",
      size : "CUSTOM-IMG", 
      state: "disabled", 
      ImageToShow: "/assets/icons/icon_trophy.svg",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
      id:"background_trophy",
      stepItems : [
        {
          id:"background_trophy_discuss",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_wheel-of-creation-poster.jpg",
          discussTitle: "teamworkSkills.wheel-of-creation.discussTitle",
        }
      ]
    }  
    ]
    
    export default LessonBackgroundSteps;